// Libraries
import dynamic from 'next/dynamic';

// Customize
import { LAYOUT, PAGE } from '@/constants/config';
import Loading from '@/components/Loading';

// Variables
const Content = dynamic(() => import('@/components/NotFound/Content'), {
  ssr: false,
  loading: () => <Loading />,
});
const ContactUs = dynamic(() => import('@/components/ContactUs'), {
  ssr: false,
  loading: () => <Loading />,
});

export default function NotFound() {
  return (
    <article className="not-found">
      <Content />
      <ContactUs />
    </article>
  );
}

NotFound.layout = LAYOUT.HEADER_NORMAL;
NotFound.canonical = [process.env.hostName, PAGE.NOT_FOUND].join('/') + '/';
NotFound.title = 'common.meta.page.not-found';
NotFound.description = 'content.not-found.description';
